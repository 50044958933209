<template>
    <div class="sub-page">
        <div class="py-2 d-none d-md-block border-bottom">
            <div class="container flex-start-center fs-px-13">
                <router-link to="/" class="text-main2">HOME</router-link>
                <i class="far fa-chevron-right mx-2 fa-xs"></i> <router-link to="/about/info" class="text-main2">서비스 소개</router-link>
                <i class="far fa-chevron-right mx-2 fa-xs"></i> 더굿페이 소개
            </div>
        </div>
        <div class="py-5 bg-main2 text-white">
            <div class="container">
                <div class="text-center">
                    <h1 class="mb-5">더굿페이 소개</h1>
                    <p class="text-gray-200">더굿페이는 온라인과 오프라인에서 결제가 필요한 경우,<br> 고객이 원하는 다양한 결제수단에 맞춰 간편하고 안전하게 결제를 진행할 수 있도록<br> 지원해주는 결제대행 서비스 입니다.</p>
                </div>
            </div>
        </div>
        <div class="pt-6">
            <div class="container">
                <h2 class="mb-5 text-center">
                    ‘PG가입 플랫폼’에서 사업자 매출상승까지<br>
                    내게 맞는 PG사를 마음대로 선택하세요.
                </h2>
                <p class="text-center">
                    사업자의 첫 단계, PG가입부터 , 쇼핑몰 결제는 물론 원거리에 떨어져 있는 고객에게<br>
                    수기결제, SMS나 카톡으로 결제요청 등으로 간편하게 결제를 받을 수 있는<br>
                    온/오프라인 결제의 모든 기능을 한방에 해결할 수 있는 결제솔루션입니다.
                </p>
            </div>
        </div>
        <section class="py-6">
            <div class="container">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="col-lg-4 col-md-12 px-4">
                        <div class="text-center mb-4">
                            <img src="@/assets/img/accounting.png" alt="" width="140" class="mb-4">
                            <h5 class="text-primary">안전하고 빠른 결제 및 정산 제공</h5>
                        </div>
                        <p>
                           온라인과 오프라인에서 상품과 서비스를 판매하고, 이에 대한 대금을 웹 및 모바일 환경에서 빠르고 간편하게 지급받을 수 있는 서비스를 제공합니다.
                        </p>
                    </div>
                    <div class="col-lg-4 col-md-12 px-4">
                        <div class="text-center mb-4">
                            <img src="@/assets/img/devices.png" alt="" width="140" class="mb-4">
                            <h5 class="text-primary">다양한 결제 수단 및 부가 서비스</h5>
                        </div>
                        <p>
                            신용카드, 실시간계좌이체, 가상계좌, 휴대폰 등의 다양한 전자지불수단 및 선정산, 예금주 조회, 계좌입금확인, ARS간편인증 등 편리한 부가 서비스를 제공합니다. 
                        </p>
                    </div>
                    <div class="col-lg-4 col-md-12 px-4">
                        <div class="text-center mb-4">
                            <img src="@/assets/img/tap.png" alt="" width="140" class="mb-4">
                            <h5 class="text-primary">온/오프라인 통합 결제</h5>
                        </div>
                        <p>
                            웹사이트에 결제시스템을 구축할 수 있으며 웹사이트 없이 App을 통하여 대면/비대면 결제도 할 수 있는 통합된 온/오프라인 전자결제시스템입니다.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { loadStyles } from '@/assets/js/loadStyles';

export default {

    mounted() {
        const defaultCss = document.querySelector(`link[href="/css/skins/default.css"]`);
        if (defaultCss !== null) {
            defaultCss.remove();
        }

        const themeElementsCss = document.querySelector(`link[href="/css/theme-elements.css"]`);
        if (themeElementsCss !== null) {
            themeElementsCss.remove();
        }

        const themeCss = document.querySelector(`link[href="/css/theme.css"]`);
        if (themeCss !== null) {
            themeCss.remove();
        }

    if(this.$route.path === '/about/info'){   
    this.addLocalStyles = loadStyles([
      require('../../../../assets/css/all.css').default,
      /* require('../../../../assets/css/bootstrap.min.css').default,
      require('../../../../assets/css/custom-bootstrap.css').default,
      require('../../../../assets/css/common.css').default, */
      'https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-dynamic-subset.min.css'
    ]);
    }
    },
  beforeDestroy() {
    
    this.addLocalStyles();
  }
}
</script>
